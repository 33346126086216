<template>
  <div class="container mx-auto p-4 max-w-2xl">
    <div class="no-print">
      <h1 class="text-2xl font-bold mb-4">Traktamente Kalkylator</h1>
      <div class="mb-4">
        <label class="block mb-1">Namn:</label>
        <input type="text" v-model="name" class="border rounded p-2 w-full" />
      </div>
      <div class="mb-4">
        <label for="country-select" class="block mb-1">Välj land:</label>
        <Multiselect
          v-model="selectedCountry"
          :options="countries"
          label="name"
          track-by="code"
          :searchable="true"
          :close-on-select="true"
          :clear-on-select="false"
          placeholder="Välj ett land"
          class="w-full"
        >
          <template #option="{ option }">
            <div class="flex items-center">
              <img :src="`https://flagcdn.com/24x18/${option.code.toLowerCase()}.png`" class="mr-2" />
              {{ option.name }}
            </div>
          </template>
          <template #single-label="{ option }">
            <div class="flex items-center">
              <img :src="`https://flagcdn.com/24x18/${option.code.toLowerCase()}.png`" class="mr-2" />
              {{ option.name }}
            </div>
          </template>
        </Multiselect>
      </div>
      <div class="mb-4">
        <label class="block mb-1">Antal dagar:</label>
        <input type="number" v-model="days" class="border rounded p-2 w-full" @change="updateDays" />
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
        <div v-for="(day, index) in daysArray" :key="index" class="p-4 border rounded">
          <label class="block mb-1">Dag {{ index + 1 }}:</label>
          <select v-model="day.type" class="border rounded p-2 w-full mb-2">
            <option value="full">Hel dag</option>
            <option value="half">Halv dag</option>
          </select>
          <label class="block mb-1">Måltider erhållna:</label>
          <div class="flex items-center mb-2" v-for="meal in meals" :key="meal">
            <input type="checkbox" :id="`meal-${index}-${meal}`" v-model="day.meals" :value="meal" class="mr-2" />
            <label :for="`meal-${index}-${meal}`">{{ meal }}</label>
          </div>
          <div class="mb-4">
            <label class="block mb-1">Kund:</label>
            <input type="text" v-model="day.customer" class="border rounded p-2 w-full" />
          </div>
        </div>
      </div>
      <div class="mb-4">
        <label class="block mb-1">Antal kilometer:</label>
        <input type="number" v-model="kilometers" class="border rounded p-2 w-full" />
      </div>
      <button @click="calculate" class="bg-blue-500 text-white p-2 rounded">Beräkna</button>
    </div>
    <div v-if="result !== null" class="mt-4">
      <h2 class="text-xl font-semibold">Beräknat traktamente och milersättning för {{ name }}</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
        <div v-for="(dayResult, index) in result.dailyResults" :key="index" class="mt-2 p-2 border rounded">
          <h3 class="font-bold">Dag {{ index + 1 }}:</h3>
          <p>Besökt kund: {{ dayResult.customer }}</p>
          <p>Traktamente: {{ dayResult.dailyAllowance.toFixed(2) }} SEK</p>
          <p>Reducerat för måltider: -{{ dayResult.reduction.toFixed(2) }} SEK</p>
          <p>Nettototal: {{ (dayResult.dailyAllowance - dayResult.reduction).toFixed(2) }} SEK</p>
        </div>
      </div>
      <div class="mt-4 p-2 border rounded">
        <h3 class="font-bold">Totalt:</h3>
        <p>Traktamente: {{ result.totalAllowance.toFixed(2) }} SEK</p>
        <p>Reducerat för måltider: -{{ result.totalReduction.toFixed(2) }} SEK</p>
        <p>Milersättning: {{ result.totalMileageCompensation.toFixed(2) }} SEK</p>
        <p>Slutsumma: {{ result.finalTotal.toFixed(2) }} SEK</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

const countries = [
  { value: "SE", name: "Sverige", code: "SE", rate: 240, mileageRate: 2.5 },
  { value: "FI", name: "Finland", code: "FI", rate: 948, mileageRate: 2.5 },
  { value: "DK", name: "Danmark", code: "DK", rate: 1274, mileageRate: 2.5 },
];

const selectedCountry = ref(countries[0].value);
const days = ref(0);
const daysArray = reactive([]);
const meals = ["Frukost", "Lunch", "Middag"];
const kilometers = ref(0);
const name = ref("");
const result = ref(null);

watch(days, () => {
  daysArray.length = 0;
  for (let i = 0; i < days.value; i++) {
    daysArray.push({ type: "full", meals: [], customer: "" });
  }
});

const calculate = () => {
  console.log(selectedCountry);
  if (!selectedCountry.value || days.value <= 0) {
    alert("Vänligen välj ett land och ange ett giltigt antal dagar.");
    return;
  }

  const c = countries.find((item) => item.value == selectedCountry.value);

  console.log("c", c.rate);

  const dailyRate = c.rate;
  const halfDayRate = dailyRate / 2;
  const mileageRate = c.mileageRate;
  let totalAllowance = 0;
  let totalReduction = 0;
  const reductionRates = {
    Frukost: 0.15,
    Lunch: 0.35,
    Middag: 0.35,
  };

  const dailyResults = daysArray.map((day) => {
    const currentRate = day.type === "full" ? dailyRate : halfDayRate;
    let dailyReduction = 0;

    day.meals.forEach((meal) => {
      dailyReduction += currentRate * reductionRates[meal];
    });

    totalAllowance += currentRate;
    totalReduction += dailyReduction;

    return {
      dailyAllowance: currentRate,
      reduction: dailyReduction,
      customer: day.customer,
    };
  });

  const totalMileageCompensation = mileageRate * kilometers.value;
  const finalTotal = totalAllowance - totalReduction + totalMileageCompensation;

  result.value = {
    dailyResults,
    totalAllowance,
    totalReduction,
    totalMileageCompensation,
    finalTotal,
  };

  console.log("result.value", result.value);
};
</script>

<style scoped>
@media print {
  .no-print {
    display: none !important;
  }
}
</style>
