<template>
  <div>
    <TraktamenteCalculator />
  </div>
</template>

<script>
import TraktamenteCalculator from "./components/TraktamenteCalculator.vue";

export default {
  components: {
    TraktamenteCalculator,
  },
};
</script>
